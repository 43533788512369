<template>
    <!-- Table Container Card -->
    <b-card no-body>
        <div class="m-2">
            <b-container class="bv-example-row">
                <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="6">
                    <b-col><b>Transition</b></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <!-- <b-col><b>LOGISTIC</b></b-col> -->
                </b-row>
                <br />

                <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="5">
                    <!-- <b-col> -->
                    <!-- <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="รหัสรายการ / TransID"
                    /> -->

                    <!-- </b-col> -->
                    <b-col>
                        <!-- <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="วันที่เริ่มต้น"
                    /> -->

                        <b-form-datepicker
                            v-model="filter.dateFrom"
                            type="text"
                            placeholder="วันที่เริ่มต้น"
                            id="datepicker-dateformat1"
                            :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                weekday: 'short',
                            }"
                            locale="en"
                        ></b-form-datepicker>
                    </b-col>
                    <b-col>
                        <b-form-datepicker
                            id="datepicker-dateformat2"
                            v-model="filter.dateTo"
                            type="text"
                            placeholder="วันที่สิ้นสุด"
                            :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                weekday: 'short',
                            }"
                            locale="en"
                        ></b-form-datepicker>

                        <!-- <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="วันที่สิ้นสุด"
                    /> -->
                    </b-col>
                    <b-col>
                        <!-- <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="ชื่อร้าน"
                    /> -->
                        <b-form-select
                            v-model="filter.status"
                            :options="options_shop"
                        ></b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-select
                            v-model="filter.shopName"
                            :options="options"
                        ></b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-input
                            v-model="filter.transferZone"
                            class="d-inline-block mr-1"
                            placeholder="สาย"
                    /></b-col>

                    <!-- {{ json_data }} -->

                    <!-- <div> -->
                    <!-- <label for="datepicker-dateformat1">Custom date format</label> -->
                    <!-- <b-form-datepicker
      id="datepicker-dateformat1"
      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
      locale="en"
    ></b-form-datepicker> -->

                    <!-- <label class="mt-3" for="datepicker-dateformat2">Short date format</label> -->
                    <!-- <b-form-datepicker
      id="datepicker-dateformat2"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
      locale="en"
    ></b-form-datepicker> -->
                    <!-- </div> -->

                    <!-- <b-col>
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="รถส่งสินค้า"
                    /></b-col> -->
                    <!-- 
                    <b-col><button><download-excel  
  class="btn btn-default"
  :data="json_data"
  :fields="json_fields"
  worksheet="My Worksheet"
  name="filename.xls"
>
  Download Excel (you can customize this with html code!)
</download-excel></button></b-col> -->

                    <!-- <Vue3JsonToExcel  fileName="myFile"  buttonText="Download Halit's excel file" :jsonData="{ hello:  'world', world:  'hello' }" /> -->

                    <!-- <b-col>
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Search...test"
                    /></b-col> -->
                </b-row>
                <br />
            </b-container>
            <b-container class="bv-example-row">
                <!-- <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="6" >
                    <b-col><b>Transition</b></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col><b>LOGISTIC</b></b-col>
                </b-row>
                <br /> -->
                

                <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="5">
                    <b-col>
                        <b-button variant="success" @click="CheckRegister1()">
                            <!-- <download-excel :data="json_data"> -->
                            Search
                            <!-- </download-excel> -->
                        </b-button>

                       

                        <b-button class="ml-2" variant="success">
                            <download-excel :data="json_data">
                                Export
                            </download-excel></b-button
                        >
                    </b-col>
                    <b-col>
                        <b-button variant="success" @click="cleardata()">
                            <!-- <download-excel :data="json_data"> -->
                           cleardata
                            <!-- </download-excel> -->
                        </b-button>

                       

                     
                    </b-col>

                    <!-- <b-col> -->
                         
                        <!-- <vue3-json-excel :json-data="json_data">
  Download Data
</vue3-json-excel> -->
                    <!-- </b-col> -->
                    <!-- <b-col>
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="รอบ"
                    /></b-col> -->
                </b-row>
                <br />
            </b-container>

            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <!-- <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <label>Entries</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                    <b-button
                        variant="primary"
                        :to="{ name: 'apps-invoice-add' }"
                    >
                        Add Record
                    </b-button>
                </b-col>

                <b-col cols="12" md="6">
                    <div
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Search...test"
                        />
                        <v-select
                            v-model="statusFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            class="invoice-filter-select"
                            placeholder="Select Status"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                </b-col> -->

                <!-- Search -->
                <!-- <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                        />
                        <v-select
                            v-model="statusFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            class="invoice-filter-select"
                            placeholder="Select Status"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                </b-col> -->
            </b-row>
        </div>

        <div>
            <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                responsive="sm"
            ></b-table>
        </div>

        <!-- <b-table
            ref="refInvoiceListTable"
            :items="fetchInvoices"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
        >
            <template #head(invoiceStatus)>
                <feather-icon icon="TrendingUpIcon" class="mx-auto" />
            </template>

       
            <template #cell(id)="data">
                <b-link
                    :to="{
                        name: 'apps-invoice-preview',
                        params: { id: data.item.id },
                    }"
                    class="font-weight-bold"
                >
                    #{{ data.value }}
                </b-link>
            </template>

         
            <template #cell(invoiceStatus)="data">
                <b-avatar
                    :id="`invoice-row-${data.item.id}`"
                    size="32"
                    :variant="`light-${
                        resolveInvoiceStatusVariantAndIcon(
                            data.item.invoiceStatus
                        ).variant
                    }`"
                >
                    <feather-icon
                        :icon="
                            resolveInvoiceStatusVariantAndIcon(
                                data.item.invoiceStatus
                            ).icon
                        "
                    />
                </b-avatar>
                <b-tooltip
                    :target="`invoice-row-${data.item.id}`"
                    placement="top"
                >
                    <p class="mb-0">
                        {{ data.item.invoiceStatus }}
                    </p>
                    <p class="mb-0">Balance: {{ data.item.balance }}</p>
                    <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
                </b-tooltip>
            </template>


            <template #cell(client)="data">
                <b-media vertical-align="center">
                    <template #aside>
                        <b-avatar
                            size="32"
                            :src="data.item.avatar"
                            :text="avatarText(data.item.client.name)"
                            :variant="`light-${resolveClientAvatarVariant(
                                data.item.invoiceStatus
                            )}`"
                        />
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.client.name }}
                    </span>
                    <small class="text-muted">{{
                        data.item.client.companyEmail
                    }}</small>
                </b-media>
            </template>

      
            <template #cell(issuedDate)="data">
                <span class="text-nowrap">
                    {{ data.value }}
                </span>
            </template>

  
            <template #cell(balance)="data">
                <template v-if="data.value === 0">
                    <b-badge pill variant="light-success"> Paid </b-badge>
                </template>
                <template v-else>
                    {{ data.value }}
                </template>
            </template>

   
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <feather-icon
                        :id="`invoice-row-${data.item.id}-send-icon`"
                        icon="SendIcon"
                        class="cursor-pointer"
                        size="16"
                    />
                    <b-tooltip
                        title="Send Invoice"
                        class="cursor-pointer"
                        :target="`invoice-row-${data.item.id}-send-icon`"
                    />

                    <feather-icon
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="EyeIcon"
                        size="16"
                        class="mx-1"
                        @click="
                            $router.push({
                                name: 'apps-invoice-preview',
                                params: { id: data.item.id },
                            })
                        "
                    />
                    <b-tooltip
                        title="Preview Invoice"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                    />

      
                    <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item>
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">Download</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{
                                name: 'apps-invoice-edit',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <feather-icon icon="CopyIcon" />
                            <span class="align-middle ml-50">Duplicate</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
        </b-table> -->
        <!-- <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                        {{ dataMeta.of }} entries</span
                    >
                </b-col>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalInvoices"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div> -->
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    BFormSelect,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import checkmemberAPI from "../../../../libs/main";

import axios from "axios";

import invoiceStoreModule from "../invoiceStoreModule";
import Button from "@/views/components/button/Button.vue";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        vSelect,
        Button,
        BFormDatepicker,
        BFormSelect,
    },

    data() {
        return {
            selected: "statusทั้งหมด",
            options: [
                { value: "ทั้งหมด", text: "ทั้งหมด" },
                { value: "ด่วนมาก", text: "ด่วนมาก" },
                { value: "ด่วน", text: "ด่วน" },
                { value: "b", text: "ปกติ" },
            ],
            selected_shop: "ชื่อร้านทั้งหมด",
            options_shop: [
                { value: "ทั้งหมด", text: "ทั้งหมด" },
                { value: "ด่วนมาก", text: "ด่วนมาก" },
                { value: "ด่วน", text: "ด่วน" },
                { value: "b", text: "ปกติ" },
            ],
            fields: [
                // {
                //     key: "code1",
                //     label: "รถส่งสินค้า",
                //     sortable: true,
                // },

                // 'index',

                {
                    key: "shop_code",
                    label: "รหัสรายการ",
                    // sortable: true,
                    // text: "#",
                    // align: "center",
                    // value: "index",

                    // Varian1t applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "transfer_zone",
                    label: "สาย / ROUT",
                    // sortable: true,
                    width: 250,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "order_date",
                    label: "วันส่งสินค้า",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "code",
                    label: "รหัส",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "shop_name",
                    label: "ร้านค้า",
                    width: 250,
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "size_small",
                    label: "สินค้า(เล็ก)",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "size_middle",
                    label: "สินค้า(กลาง)",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "size_large",
                    label: "สินค้า(ใหญ่)",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "remark",
                    label: "อื่น ๆ",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "total",
                    label: "รวม",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "total",
                    label: "NO.INVOICE",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
                {
                    key: "status_order",
                    label: "สถานะ ชำระเงิน",
                    // sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    // variant: "danger",
                },
            ],
            items: [
                { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
                { age: 21, first_name: "Larsen", last_name: "Shaw" },
                { age: 89, first_name: "Geneva", last_name: "Wilson" },
                { age: 38, first_name: "Jami", last_name: "Carney" },
            ],
            json_data: [],

            filter: {
                dateFrom: "",
                dateTo: "",
                status: "",
                transferZone: "",
                shopName: '',
            },
            options :[]
        };
    },
    methods: {
        async CheckRegister() {
            try {
                const response = await axios.post(
                    `http://202.129.206.107:11009/listshop`
                );

                console.log("response", response);

               response.data;

                for (let i = 0; i < response.data.length; i++) {
                    //   if(this.items.shopName)this.
                    this.options.push(response.data[i].shopName);
                }

                console.log("this.options", this.options)



                // this.options = response.data.shopName;

                console.log("data", data1);

                // console.log(`GET: Here's the list of todos`, todoItems);

                return todoItems;
            } catch (errors) {
                console.error(errors);
            }
        },

        async  cleardata(){
            this.filter = {
                dateFrom: "",
                dateTo: "",
                status: "",
                transferZone: "",
                shopName: '',
            }
            this.CheckRegister();
        this.CheckRegister1();
        },

        async CheckRegister1() {
            try {
                const response = await axios.post(
                    `http://202.129.206.107:11009/listorder`,
                    this.filter
                    // {
                    //     dateFrom: "",
                    //     dateTo: "",
                    //     status: "",
                    //     transferZone: "",
                    //     shopName: "",
                    // }
                );

                // const todoItems = response.data;
                this.items = response.data;
                this.json_data = this.items;

                console.log("response1", response);

                // const todoItems = response.data;
                // this.items = response.data;
                // this.json_data = this.items;
                // this.json_data = response.data
                // console.log("data", data1);

                // console.log(`GET: Here's the list of todos`, todoItems);

                // return todoItems;
            } catch (errors) {
                console.error(errors);
            }
        },
    },

    mounted() {
        this.CheckRegister();
        this.CheckRegister1();
        // this.CheckRegister1();
        // axios.get('https://jsonplaceholder.typicode.com/posts/1')
        // axios
        //     .post("http://202.129.206.107:11009/listorder", {
        //       data,
        //     })
        //     .then((response) => console.log(response));
        // axios
        //     .post("http://202.129.206.107:11009/listorder", {
        //         dateFrom: "",
        //         dateTo: "",
        //         status: "",
        //         transferZone: "",
        //         shopName: "",
        //     })
        //     .then((response) => console.log(response));
        // axios
        //     .post("http://202.129.206.107:11009/listshop")
        //     .then((response) => console.log(response));
    },

    //   },

    // async CheckRegister() {
    //         let data = '';
    //         this.loading = false;
    //         await checkmemberAPI({
    //             payload: this.userId,
    //             cbSuccess: async (res) => {
    //                 console.log("res", res.data);
    //                 data = res.data;

    //                 this.CheckOrder(data);
    //                 // if (data.isActive == "Y") {
    //                 //     //ไปหน้าปกติ
    //                 //     console.log("Y");
    //                 //     this.$router.push(`/product-detail`);
    //                 // } else {
    //                 //     this.alertMessage("กรุณาติดต่อ Admin");
    //                 //     // this.$router.push(`/`);
    //                 //     console.log("N");
    //                 //     //ถ้าไม่เท่ากับ Y ไป ลงทะเบียน
    //                 // }
    //             },
    //             cbError: (e) => {
    //                 console.log("e", e);
    //             },
    //         });
    //     },

    setup() {
        const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
            store.registerModule(
                INVOICE_APP_STORE_MODULE_NAME,
                invoiceStoreModule
            );

        // const data = reactive({
        //     formData: {
        //         dateFrom : '',
        //         dateTo : '',
        //         status : '',
        //         transferZone : '',
        //         shopName : '',
        //     },
        // });

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
                store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
        });

        const statusOptions = [
            "Downloaded",
            "Draft",
            "Paid",
            "Partial Payment",
            "Past Due",
        ];

        // const json_data = [
        //     {
        //         name: "Tony Peña",
        //         city: "New York",
        //         country: "United States",
        //         birthdate: "1978-03-15",
        //         phone: {
        //             mobile: "1-541-754-3010",
        //             landline: "(541) 754-3010",
        //         },
        //     },
        //     {
        //         name: "Thessaloniki",
        //         city: "Athens",
        //         country: "Greece",
        //         birthdate: "1987-11-23",
        //         phone: {
        //             mobile: "+1 855 275 5071",
        //             landline: "(2741) 2621-244",
        //         },
        //     },
        // ];

        const {
            fetchInvoices,
            tableColumns,
            perPage,
            currentPage,
            totalInvoices,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refInvoiceListTable,

            statusFilter,

            refetchData,

            resolveInvoiceStatusVariantAndIcon,
            resolveClientAvatarVariant,
        } = useInvoicesList();

        return {
            fetchInvoices,
            tableColumns,
            perPage,
            currentPage,
            totalInvoices,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refInvoiceListTable,

            statusFilter,
            // json_data,
            refetchData,

            statusOptions,

            avatarText,
            resolveInvoiceStatusVariantAndIcon,
            resolveClientAvatarVariant,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
